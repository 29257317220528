import createStore from 'react-waterfall';
import createHistory from 'history/createBrowserHistory';
import ReactGA from 'react-ga';
import ApiClient from './ApiClient';
import Config from '~/config';
import Elections from '~/helpers/constants/Elections';
import { findElectorate, VicElectorateConfig } from '~/helpers/constants/Electorates';
import ElectorateTypes from '~/helpers/constants/ElectorateTypes';
import LocationSources from '~/helpers/constants/LocationSources';
import { sortGuesses } from '~/helpers/GuessHelper';
import { groupBoothsByElectorateAndDay } from '~/helpers/BoothHelper';
import { getConfig } from '~/helpers/ConfigHelper';

const stateConfig = {
  initialState: {
    election: Config.electionCode
      ? Elections[Config.electionCode]
      : null,
    electorates: null,
    candidateInfo: {},
    userLocation: null,
    guesses: [],
    attempts: 0,
    maybeOverseas: false,
    preferences: null,
    booths: Config.election ? groupBoothsByElectorateAndDay(Config.election.booths) : null,
    history: createHistory(),
    config: getConfig(Config, Elections[Config.electionCode], VicElectorateConfig),
    authToken: null,
  },
  actionsCreators: {
    guessElectorate: async (state, self, lat, lng, accuracy, source) => {
      const electorates = await ApiClient.getElectorates(state.config, lat, lng);
      if (
        !(electorates.lower)
        || !(findElectorate(electorates.lower, state.config.electorates.lower))) {
        return {
          guesses: sortGuesses(state.guesses),
          attempts: state.attempts + 1,
          maybeOverseas: true,
        };
      }
      if (source === LocationSources.ADDRESS) {
        const foundElectorate = findElectorate(electorates.lower, state.config.electorates.lower);
        ReactGA.set({ dimension1: foundElectorate });
        ReactGA.event({
          category: 'Electorate Select',
          action: `Electorate selected by ${LocationSources.ADDRESS}`,
        });
        ReactGA.event({
          category: 'Section View',
          action: 'Viewed vote',
        });
        if (window.hj) {
          window.hj('trigger', 'how');
        } else {
          setTimeout(() => window.hj('trigger', 'how'), 5000);
        }
        if (state.election.electorates.upper) {
          return {
            electorates: {
              lower: {
                name: foundElectorate,
                /** Both keys here for backwards-compatibility as not everything is typed yet. */
                type: state.config.electorates.lowerType,
                electorateType: state.config.electorates.lowerType,
              },
              upper: {
                name: state.election.electorates.lowerToUpper(foundElectorate),
                /** Both keys here for backwards-compatibility as not everything is typed yet. */
                type: state.config.electorates.upperType,
                electorateType: state.config.electorates.upperType,
              },
            },
            userLocation: {
              lat,
              lng,
            },
          };
        }
        return {
          electorates: {
            lower: {
              name: foundElectorate,
              /** Both keys here for backwards-compatibility as not everything is typed yet. */
              type: state.config.electorates.lowerType,
              electorateType: state.config.electorates.lowerType,
            },
          },
          userLocation: {
            lat,
            lng,
          },
        };
      }
      const guess = {
        lower: findElectorate(electorates.lower, state.config.electorates.lower),
        lat,
        lng,
        accuracy,
        source,
        timestamp: new Date().getTime(),
      };
      return {
        guesses: sortGuesses([...state.guesses, guess]),
        attempts: state.attempts + 1,
        maybeOverseas: false,
      };
    },
    setElectoratesByLower: (state, self, lowerElectorate) => {
      let electorates;
      if (state.election.electorates.upper) {
        electorates = {
          lower: {
            name: findElectorate(lowerElectorate, state.config.electorates.lower),
            /** Both keys here for backwards-compatibility as not everything is typed yet. */
            type: state.config.electorates.lowerType,
            electorateType: state.config.electorates.lowerType,
          },
          upper: {
            name: state.election.electorates.lowerToUpper(lowerElectorate),
            /** Both keys here for backwards-compatibility as not everything is typed yet. */
            type: state.config.electorates.upperType,
            electorateType: state.config.electorates.upperType,
          },
        };
      } else {
        electorates = {
          lower: {
            name: findElectorate(lowerElectorate, state.config.electorates.lower),
            /** Both keys here for backwards-compatibility as not everything is typed yet. */
            type: state.config.electorates.lowerType,
            electorateType: state.config.electorates.lowerType,
          },
        };
      }

      return {
        electorates,
        config: getConfig(
          Config,
          Elections[Config.electionCode],
          VicElectorateConfig,
          electorates,
        ),
      };
    },
    clearElectorates: (state) => {
      if (state.history.location.pathname !== '/') {
        state.history.push('/');
      }
      ReactGA.ga('send', {
        hitType: 'pageview',
        page: '/',
        sessionControl: 'start',
      });
      return ({
        electorates: null,
        config: getConfig(Config, Elections[Config.electionCode], VicElectorateConfig),
        userLocation: null,
      });
    },
    setLocation: (state, self, lat, lng) => ({
      userLocation: {
        lat,
        lng,
      },
    }),
    getPreferences: async (state) => {
      const preferences = await ApiClient.getPreferences(state.config, state.authToken);
      return {
        preferences,
      };
    },
    getCandidateInfo: async ({ config, candidates, electorates: { lower, upper } }) => {
      const newCandidates = {
        ...candidates,
      };
      const lowerCandidate = await ApiClient.getCandidateInfo(config, lower);
      if (lowerCandidate.heroImage && lowerCandidate.heroImage.indexOf('generic') !== -1) {
        lowerCandidate.heroImage = undefined;
      }
      newCandidates[lower.name] = lowerCandidate;
      if (upper && config.getCandidateInfoUpper) {
        const upperCandidate = await ApiClient.getCandidateInfo(config, upper);
        newCandidates[upper.name] = upperCandidate;
      }
      return {
        candidateInfo: newCandidates,
      };
    },
    getBooths: async (state, self, electorate) => {
      const newBooths = await ApiClient.getBooths(state.config, electorate);
      return {
        booths: groupBoothsByElectorateAndDay(newBooths),
      };
    },
    postPacket: async (state, self, firstName, lastName, email, postcode) => {
      ApiClient.postPacket(state.config, firstName, lastName, email, postcode);
    },
    setAuthToken: (state, self, token) => ({
      authToken: token,
    }),
  },
};

export const { Provider, connect, actions } = createStore(stateConfig);
