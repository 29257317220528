import React, { useState, Fragment, ReactElement } from 'react';
import styled from 'styled-components';
import { Button, Row, Col } from 'react-bootstrap';
import Hidden from '@material-ui/core/Hidden';
import { connect } from '~/store';
import { daysUntil } from '~/helpers/DateHelper';
import AddressLookup from '~/components/sections/ElectorateSelect/AddressLookup/AddressLookup';

type ElectorateSelectProps = {
  /** List of guessed electorates based on browser geolocation. */
  guesses: any[];
  /** Function to call once user has either confirmed a guessed electorate or provided one. */
  confirmElectorate: (...args: any[]) => any;
  /** Is it election day today? */
  isElectionDay: boolean;
  /** All electorates for this election, grouped by type. */
  electorates: {
    lower: string[];
    upper: string[];
    lowerToUpper: (string) => string;
  };
  /** Should the manual electorate selection first ask for the 'upper' electorate and then the 'lower?
   * This is used in Federal elections to allow the user to filter by state.
   */
  groupElectoratesOnSelect?: boolean;
  /** The status of the attempt to get the user's location via the browser geolocation API. */
  locationStatus: string;
  /** How many times we have retrieved coordinates for the user and attempted to get an electorate from them. */
  attempts: number;
};

/**
 * Component that allows the user to select their electorate.
 * Offers three methods:
 * - Automatic via device GPS (browser geolocation API)
 * - Automatic via address entry
 * - Manual via select list
 * Messy, needs to be split out and cleaned up.
 */
export const ElectorateSelect: React.SFC<ElectorateSelectProps> = ({
  guesses,
  confirmElectorate,
  isElectionDay,
  electorates,
  groupElectoratesOnSelect,
  locationStatus,
  attempts,
}) => {
  /* Uses React useState hook to manage state without being a class-based component. */
  const [selectedUpper, selectUpper] = useState('-');

  /**
   * Function intended to be used to render buttons for selecting upper electorate.
   * ESLint correctly points out this function is not used. Need to find out why.
   */
  const renderUpperSelectButtons = (): ReactElement => (
    <>
      <br />
      {electorates.upper.map((el) => (
        <Button style={{ marginRight: '5px' }} onClick={(): void => selectUpper(el)}>
          {el}
        </Button>
      ))}
    </>
  );

  /**
   * Helper function that returns the first guess if guess is available,
   * or null otherwise.
   * Relies on the fact that guesses passed to this component should be sorted
   * by implied accuracy.
   */
  const bestGuess = guesses.length ? guesses[0] : null;

  return (
    <>
      <Row style={{ textAlign: 'center', padding: '5px' }} className="u-bg-green-fun">
        <h1 style={{ color: 'white', width: '100%' }}>Choose your electorate!</h1>
      </Row>
      <Row style={{
        paddingLeft: '10px', paddingRight: '10px', paddingTop: '10px', textAlign: 'center',
      }}
      >
        <Col md="4" style={{ minHeight: '150px' }}>
          <h3>Guessing your electorate...</h3>
          <p>We&apos;ll use your device&apos;s GPS</p>
          {bestGuess && bestGuess.lower ? (
            <div>
              <Button size="sm" onClick={() => confirmElectorate(bestGuess.lower, bestGuess)}>
                Yes, I&apos;m in {bestGuess.lower}
              </Button>
            </div>
          ) : (
            <>
              {!locationStatus && (
                <Button size="sm" disabled>
                  Attempting to guess...
                </Button>
              )}
              {locationStatus
                && locationStatus !== 'GOT_LOCATION' && (
                  <Button size="sm" disabled>
                    No luck :(
                  </Button>
              )}
              {bestGuess
                && !bestGuess.lower && (
                  <Button size="sm" disabled>
                    No luck :(
                  </Button>
              )}
              {!bestGuess && attempts >= 1 && <b>You seem to be outside the state.</b>}
            </>
          )}
        </Col>
        <Col md="4">
          <h3>Select your electorate</h3>
          <p>My electorate is:</p>
          {groupElectoratesOnSelect && (
            <div>
              <select value={selectedUpper} onChange={(e) => selectUpper(e.target.value)}>
                <option value="-" key="-">
                  -
                </option>
                {electorates.upper.map((region) => (
                  <option value={region} key={region}>
                    {region}
                  </option>
                ))}
              </select>
            </div>
          )}
          {groupElectoratesOnSelect
            && selectedUpper !== '-' && (
              <div>
                <select value="-" onChange={(e) => confirmElectorate(e.target.value)}>
                  <option value="-" key="-">
                    -
                  </option>

                  {electorates.lower.filter((el) => electorates.lowerToUpper(el) === selectedUpper).map((district) => (
                    <option value={district} key={district}>
                      {district}
                    </option>
                  ))}
                </select>
              </div>
          )}
          {!groupElectoratesOnSelect && (
            <div>
              <select value="-" onChange={(e) => confirmElectorate(e.target.value)}>
                <option value="-" key="-">
                  -
                </option>
                {electorates.lower.map((district) => (
                  <option value={district} key={district}>
                    {district}
                  </option>
                ))}
              </select>
            </div>
          )}
        </Col>
        <Col md="4" style={{ minHeight: '200px' }}>
          <Hidden smUp>
            <div style={{ height: '20px ' }} />
          </Hidden>
          <h3>Find your electorate</h3>
          <p>Enter your address</p>
          <AddressLookup />
        </Col>
      </Row>
    </>
  );
};

ElectorateSelect.defaultProps = {
  groupElectoratesOnSelect: false,
};

export default connect((state) => ({
  guesses: state.guesses,
  attempts: state.attempts,
  isElectionDay: daysUntil(state.election.date) === 0,
  electorates: state.config.electorates,
  groupElectoratesOnSelect: state.config.groupElectoratesOnSelect,
}))(ElectorateSelect);
