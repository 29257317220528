import React from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useScrollTrigger } from '@material-ui/core';
import { SelectedElectorates } from '~/helpers/types/Electorate';
import { Config } from '~/helpers/types/Config';
import { BallotType } from '../../../../helpers/types/BallotType';

type HowToVoteTabsProps = {
  config: Config;
  currentBallot: BallotType;
  changeBallot: (_: any, ballotType: BallotType) => void;
};

/**
 * The header that appears at the top of the Mobile HTV app.
 * Wrapped and styled version of Material UI AppBar.
 */
const HowToVoteTabs: React.SFC<HowToVoteTabsProps> = (props) => {
  const { config, currentBallot, changeBallot } = props;
  const { lowerLabel, upperLabel } = config.labels;
  const floating = useScrollTrigger({ disableHysteresis: true });
  const barClass = floating ? 'sticky' : 'static';
  return (
    <AppBar style={{ top: floating ? '0' : '100px' }} position={barClass}>
      <Tabs variant="fullWidth" style={{ flexGrow: 1, backgroundColor: '#F7941E' }} value={currentBallot} onChange={changeBallot} TabIndicatorProps={{ style: { backgroundColor: 'white' } }}>
        <Tab label={lowerLabel} style={{ color: 'white', fontWeight: 'bold' }} value="lower" />
        <Tab label={upperLabel} style={{ color: 'white', fontWeight: 'bold' }} value="upper" />
      </Tabs>
    </AppBar>
  );
};

export default HowToVoteTabs;
