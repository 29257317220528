/** Constants with the background colour of ballot papers. */
/* TODO: Refactor this file and merge with other colour logic. */

/** Colours of the ballot papers for the upper house in Victorian State elections. */
export const UpperBallotColours = {
  'Eastern Victoria': '#E6F1F9',
  'North Eastern Metropolitan': '#FDEFF0',
  'Northern Metropolitan': '#FCF8D5',
  'Northern Victoria': '#ECF4E2',
  'South Eastern Metropolitan': '#F3F4F2',
  'Southern Metropolitan': '#F6F0DB',
  'Western Metropolitan': '#E1F2EF',
  'Western Victoria': '#F0EEF4',
};

/** Colour of the ballot paper for the lower house in ??? elections. */
export const LowerBallotColour = '#FFFFFF';
